<template>
  <div class="animated fadeIn">
    <div>
      <h4 class="mb-3">
        {{ form.driver.surname }} {{ form.driver.name }} <span v-if="form.driver.patronymic">{{ form.driver.patronymic }}</span>
        <span
          v-if="form.driver.isCancel"
          class="text-danger"
        > (пометка на удаление)</span>
        <span v-else>
          <span
            v-if="form.driver.status === 1"
            class="text-success"
          > (допущен) </span>
          <span
            v-if="form.driver.status === 2"
            class="text-warning"
          > (на верификации) </span>
          <span
            v-if="form.driver.status === 4"
            class="text-primary"
          > (проверка логистом) </span>
          <span
            v-if="form.driver.status === 5"
            class="text-danger"
          > (отклонен) </span>
          <div v-if="(form.driver.status === 0 || form.driver.status === 3) && form.driver.blockedSecurity &&
                form.driver.validationStatus === 'Проверка выполнена' && 
                form.driver.decisionOnDriver === 'Нет решения'"
            >
                <span
                class="text-danger small"
              >(Проверка завершена. Водитель будет допущен к рейсу при условии предоставления следующих документов <br>
              (инст-ция водителя, инф. лист, лист инструктажа, фото водителя). Заполняются водителем на складе загрузки.)</span>
            </div>

            <div v-else>
                <span
                v-if="form.driver.status === 3"
                class="text-danger"
              >(не допущен) &ensp;</span>
              <span
              v-if="form.driver.status === 0"
              class="text-secondary"
              > (заблокирован СБ) &ensp;</span>
              <span
                v-if="form.driver.blockedSecurity"
                class="badge badge-danger"
              >Блокировка СБ &ensp;</span>
              <span
                v-if="form.driver.validationStatus === 'Проверка выполнена' &&  form.driver.decisionOnDriver === 'Нет решения'"
                class="text-danger font-weight-bold"
              >
                (Не хватает документов для принятия решения) &ensp;
              </span>
            </div>
        </span>
      </h4>
      <p
        v-if="form.customerComment && form.driver.status === 5"
        class="text-danger"
      >
        Причина отклонения: "{{ form.customerComment }}"
      </p>
      <p
        v-else-if="form.customerComment && form.driver.status === 0"
        class="text-danger"
      >
        Причина возврата на редактирование: "{{ form.customerComment }}"
      </p>
      <div class="form-sections">
        <div class="custom-fieldset">
          <b-row>
            <b-col md="12">
              <b-form-group
                label="Вид перевозки"
              >
                <b-form-radio-group v-model="accessType">
                  <b-form-radio
                    v-if="accessType=== 'auto'"
                    v-model="accessType"
                    name="accessType"
                    value="auto"
                    disabled
                  >
                    Автоперевозка
                  </b-form-radio>
                  <b-form-radio
                    v-if="accessType=== 'container'"
                    v-model="accessType"
                    name="accessType"
                    value="container"
                    disabled
                  >
                    ЖД перевозка (только на ЖД рейсы на доставку контейнера до ЖД станции)
                  </b-form-radio>
                  <b-form-radio
                    v-if="accessType=== 'cargoFlow'"
                    v-model="accessType"
                    name="accessType"
                    value="cargoFlow"
                    disabled
                  >
                    Услуга грузопровод (только по услуги грузопровод)
                  </b-form-radio>
                  <b-form-input
                    v-model="accessType"
                    disabled
                    class="d-none"
                  />
                </b-form-radio-group>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="form.driver.driverAccessAuto">
            <b-col md="12">
              <b-form-group
                label="Тип проверки"
              >
                <b-form-radio-group v-model="form.checkType">
                  <b-form-radio
                    v-if="form.checkType=== 0"
                    name="checkType"
                    :value="0"
                    disabled
                  >
                    Обычная проверка (личное посещение водителя СБ ПЭК – обычный пакет документов для проверки)
                  </b-form-radio>
                  <b-form-radio
                    v-if="form.checkType=== 1"
                    name="checkType"
                    :value="1"
                    disabled
                  >
                    Экспресс проверка (без личного посещения водителя СБ ПЭК – расширенный пакет документов)
                  </b-form-radio>
                  <b-form-input
                    v-model="form.checkType"
                    disabled
                    class="d-none"
                  />
                </b-form-radio-group>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="form.checkType===0">
            <b-col md="4">
              <b-form-group
                class="form-group-el-select required"
                label="Отделение ПЭК для посещения его водителем для окончательной проверки"
              >
                <el-select
                  id="checkLocation"
                  v-model="form.checkLocation"
                  class="form-control"
                  name="checkLocation"
                  placeholder="Выберите отделение"
                  disabled
                >
                  <el-option
                    v-for="item in driverCheckLocations"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  />
                </el-select>
                <b-form-invalid-feedback id="input1LiveFeedback1">
                  Это поле обязательно для заполнения
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
          <h5 class="custom-fieldset__title">
            Паспортные данные
          </h5>
          <b-row>
            <b-col md="4">
              <b-form-group
                id="passportInputGroup1"
                class="form-group-el-select"
                label="Паспорт"
                label-for="passport-type"
              >
                <el-select
                  id="passport-type"
                  v-model="form.driver.passportType"
                  disabled
                  class="form-control"
                  name="passport-type"
                  placeholder="Выберите тип паспорта"
                  clearable
                  :loading="loading"
                >
                  <el-option
                    v-for="item in passportTypes"
                    :key="item.value"
                    :label="item.text"
                    :value="item.value"
                  />
                </el-select>
              </b-form-group>
            </b-col>
            <b-col md="8">
              <b-row>
                <b-col md="4">
                  <b-form-group
                    id="passportSeriesInputGroup2"
                    label="Серия"
                    label-for="passport-series"
                  >
                    <b-form-input
                      id="passport-series"
                      v-model="form.driver.passportSeries"
                      disabled
                      type="text"
                      name="passport-series"
                      autocomplete="passport-series"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="8">
                  <b-form-group
                    id="passportNumberInputGroup3"
                    label="Номер"
                    label-for="passport-series"
                  >
                    <b-form-input
                      id="passport-number"
                      v-model="form.driver.passportNumber"
                      disabled
                      type="number"
                      name="passport-number"
                      autocomplete="passport-number"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
            <b-col md="4">
              <b-form-group
                id="passportIssueDateInputGroup4"
                label="Дата выдачи"
                label-for="passport-issue-date"
              >
                <el-date-picker-input
                  id="passport-issue-date"
                  v-model="form.driver.passportIssueDate"
                  disabled
                  type="date"
                  name="passport-issue-date"
                  autocomplete="passport-issue-date"
                  format="dd.MM.yyyy"
                  :picker-options="pickerOptions"
                />
              </b-form-group>
            </b-col>
            <b-col md="8">
              <b-form-group
                id="passportIssuedByInputGroup5"
                label="Кем выдан"
                label-for="passport-issued-by"
              >
                <b-form-input
                  id="passport-issued-by"
                  v-model="form.driver.passportIssuedBy"
                  disabled
                  type="text"
                  name="passport-issued-by"
                  autocomplete="passport-issued-by"
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                id="surnameInputGroup6"
                label="Фамилия"
                label-for="surname"
              >
                <b-form-input
                  id="surname"
                  v-model="form.driver.surname"
                  disabled
                  type="text"
                  name="surname"
                  autocomplete="surname"
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                id="surnameInputGroup7"
                label="Имя"
                label-for="name"
              >
                <b-form-input
                  id="name"
                  v-model="form.driver.name"
                  disabled
                  type="text"
                  name="name"
                  autocomplete="name"
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                id="surnameInputGroup8"
                label="Отчество"
                label-for="patronymic"
              >
                <b-form-input
                  id="patronymic"
                  v-model="form.driver.patronymic"
                  disabled
                  type="text"
                  name="patronymic"
                  autocomplete="patronymic"
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                id="birthDateInputGroup19"
                label="Дата рождения"
                label-for="birth-date"
              >
                <el-date-picker-input
                  id="birth-date"
                  v-model="form.driver.birthDate"
                  disabled
                  type="date"
                  name="birth-date"
                  autocomplete="birth-date"
                  format="dd.MM.yyyy"
                  :picker-options="pickerOptions"
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                id="innInputGroup7"
                label="ИНН водителя"
                label-for="inn"
              >
                <b-form-input
                  id="inn"
                  v-model="form.driver.inn"
                  type="text"
                  name="inn"
                  aria-describedby="innInputGroup7"
                  autocomplete="inn"
                  disabled
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                id="passportScreenFirstInputGroup16"
                label="Главная страница паспорта"
                label-for="passport-main-page"
              >
                <b-form-file
                  id="passport-main-page"
                  v-model="form.passportScreenFirst"
                  v-b-popover.hover="'Максимальный объем загружаемого файла, не должен превышать 15MB'"
                  disabled
                  name="passport-main-page"
                  accept=".txt, .rtf, .doc, .docx, .pdf, .rar, .zip, .xls, .xlsx, .jpg, .jpeg, .png, .svg"
                  placeholder="Файл не выбран"
                  browse-text="Выбрать"
                  drop-placeholder="Перетащите файл сюда"
                />
                <div
                  v-if="form.passportScreenFirst && form.passportScreenFirst.file"
                  class="mt-2"
                >
                  Загруженный файл:
                  <a
                    :href="'/files/' + form.passportScreenFirst.file.guid"
                    target="_blank"
                    @click.prevent="downloadFile(form.passportScreenFirst.file)"
                  >{{ form.passportScreenFirst.file.originalName }}</a>
                </div>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                id="passportScreenSecondInputGroup17"
                label="Страница с пропиской"
                label-for="passport-registration-page"
              >
                <b-form-file
                  id="passport-registration-page"
                  v-model="form.passportScreenSecond"
                  v-b-popover.hover="'Максимальный объем загружаемого файла, не должен превышать 15MB'"
                  disabled
                  name="passport-registration-page"
                  accept=".txt, .rtf, .doc, .docx, .pdf, .rar, .zip, .xls, .xlsx, .jpg, .jpeg, .png, .svg"
                  placeholder="Файл не выбран"
                  browse-text="Выбрать"
                  drop-placeholder="Перетащите файл сюда"
                />
                <div
                  v-if="form.passportScreenSecond && form.passportScreenSecond.file"
                  class="mt-2"
                >
                  Загруженный файл:
                  <a
                    :href="'/files/' + form.passportScreenSecond.file.guid"
                    target="_blank"
                    @click.prevent="downloadFile(form.passportScreenSecond.file)"
                  >{{ form.passportScreenSecond.file.originalName }}</a>
                </div>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                id="contractFilesInputGroup18"
                label="Документы договорных отношений водителя и работодателя"
                label-for="contract-file"
              >
                <b-form-file
                  id="contract-file"
                  v-b-popover.hover="'Максимальный объем загружаемого файла, не должен превышать 15MB'"
                  disabled
                  name="contract-file"
                  accept=".txt, .rtf, .doc, .docx, .pdf, .rar, .zip, .xls, .xlsx, .jpg, .jpeg, .png, .svg"
                  placeholder="Выберите файл"
                  browse-text="Выбрать"
                  drop-placeholder="Перетащите файл сюда"
                  multiple
                />
                <div
                  v-if="form.contractFiles.length > 0 "
                  class="mt-2"
                >
                  Загруженные файлы:
                  <span
                    v-for="(file, index) in form.contractFiles"
                    :key="`file-${index}`"
                  >
                    <a
                      :href="'/files/' + (file.file.guid !== undefined) ? file.file.guid : 'File not found'"
                      target="_blank"
                      @click.prevent="downloadFile(file.file)"
                    >{{ file.file.originalName }}</a>;
                  </span>
                </div>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                id="driverLicenseInputGroup9"
                label="Номер водительского удостоверения"
                label-for="drivers-license"
              >
                <b-form-input
                  id="drivers-license"
                  v-model="form.driver.driverLicense"
                  disabled
                  type="text"
                  name="drivers-license"
                  aria-describedby="input1LiveFeedback9"
                  autocomplete="drivers-license"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                id="driverLicenseScreensGroup15"
                label="Водительское удостоверение"
                label-for="drivers-license-cover-page"
              >
                <b-form-file
                  id="drivers-license-cover-page"
                  v-b-popover.hover="'Максимальный объем загружаемого файла, не должен превышать 15MB'"
                  disabled
                  name="drivers-license-cover-page"
                  accept=".txt, .rtf, .doc, .docx, .pdf, .rar, .zip, .xls, .xlsx, .jpg, .jpeg, .png, .svg"
                  placeholder="Выберите файл"
                  browse-text="Выбрать"
                  drop-placeholder="Перетащите файл сюда"
                  multiple
                />
                <div
                  v-if="form.driverLicenseScreens.length > 0"
                  class="mt-2"
                >
                  Загруженные файлы:
                  <span
                    v-for="(file, index) in form.driverLicenseScreens"
                    :key="`file-${index}`"
                  >
                    <a
                      :href="'/files/' + file.file.guid"
                      target="_blank"
                      @click.prevent="downloadFile(file.file)"
                    >{{ file.file.originalName }}</a>;
                  </span>
                </div>
              </b-form-group>
            </b-col>
            <b-col
              v-if="accessType==='cargoFlow' || form.checkType===1"
              md="4"
            >
              <b-form-group
                id="instruction-label"
                label="Инструкция (подписанная водителем собственноручно)"
                label-for="instruction"
              >
                <div
                  v-if="form.instruction && form.instruction.file"
                  class="mt-2"
                >
                  Загруженный файл:
                  <a
                    :href="'/files/' + form.instruction.file.guid"
                    target="_blank"
                    @click.prevent="downloadFile(form.instruction.file)"
                  >{{ form.instruction.file.originalName }}</a>
                </div>
                <example-document code="instruction" />
              </b-form-group>
            </b-col>
            <b-col
              v-if="accessType==='cargoFlow' || form.checkType===1 || accessType==='container'"
              md="4"
            >
              <b-form-group
                id="infoSheet-label"
                label="Инф. Лист (подписанный водителем собственноручно)"
                label-for="infoSheet"
              >
                <div
                  v-if="form.infoSheet && form.infoSheet.file"
                  class="mt-2"
                >
                  Загруженный файл:
                  <a
                    :href="'/files/' + form.infoSheet.file.guid"
                    target="_blank"
                    @click.prevent="downloadFile(form.infoSheet.file)"
                  >{{ form.infoSheet.file.originalName }}</a>
                </div>
                <example-document code="infoSheet" />
              </b-form-group>
            </b-col>
            <b-col
              md="4"
            >
              <b-form-group
                v-if="accessType==='cargoFlow' || form.checkType===1"
                id="criminalZonesSheet-label"
                label="Лист криминогенных зон (подписанный водителем собственноручно)"
                label-for="criminalZonesSheet"
              >
                <div
                  v-if="form.criminalZonesSheet && form.criminalZonesSheet.file"
                  class="mt-2"
                >
                  Загруженный файл:
                  <a
                    :href="'/files/' + form.criminalZonesSheet.file.guid"
                    target="_blank"
                    @click.prevent="downloadFile(form.criminalZonesSheet.file)"
                  >{{ form.criminalZonesSheet.file.originalName }}</a>
                </div>
                <example-document code="criminalZonesSheet" />
              </b-form-group>
            </b-col>
            <b-col
              v-if="accessType==='cargoFlow' || form.checkType===1 || accessType==='container'"
              md="4"
            >
              <b-form-group
                id="selfie-label"
                label="Фото водителя (селфи)"
                label-for="selfie"
              >
                <div
                  v-if="form.selfie && form.selfie.file"
                  class="mt-2"
                >
                  Загруженный файл:
                  <a
                    :href="'/files/' + form.selfie.file.guid"
                    target="_blank"
                    @click.prevent="downloadFile(form.selfie.file)"
                  >{{ form.selfie.file.originalName }}</a>
                </div>
              </b-form-group>
            </b-col>
            <b-col
              v-if="accessType==='container'"
              md="4"
            >
              <b-form-group
                id="power-of-attorney-label"
                label="Довереность от перевозчика"
                label-for="power-of-attorney"
              >
                <div
                  v-if="form.powerOfAttorney && form.powerOfAttorney.file"
                  class="mt-2"
                >
                  Загруженный файл:
                  <a
                    :href="'/files/' + form.powerOfAttorney.file.guid"
                    target="_blank"
                    @click.prevent="downloadFile(form.powerOfAttorney.file)"
                  >{{ form.powerOfAttorney.file.originalName }}</a>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
        <div class="custom-fieldset">
          <h5 class="custom-fieldset__title">
            Контактная информация
          </h5>
          <div
            v-for="(phone, index) in form.driverPhones"
            :key="`phone-${index}`"
          >
            +{{ phone.countryCode.code }} ({{ phone.areaCode }})-{{ phone.localNumber }}
            <span
              v-if="phone.additionalCode"
            >({{ phone.additionalCode }})</span>
          </div>
        </div>
        <div class="custom-fieldset">
          <h5 class="custom-fieldset__title">
            Данные по допускам
          </h5>
          <b-row>
            <b-col md="4">
              <b-form-checkbox
                id="access-auto"
                v-model="form.driver.driverAccessAuto"
                name="access-auto"
              >
                Допуск Авто
              </b-form-checkbox>
            </b-col>
            <b-col md="4">
              <b-form-checkbox
                id="access-container"
                v-model="form.driver.driverAccessContainer"
                name="access-container"
                :disabled="form.driver.driverAccessAuto"
              >
                Допуск Контейнер
              </b-form-checkbox>
            </b-col>
            <b-col md="4">
              <b-form-checkbox
                id="access-cargo-flow"
                v-model="form.driver.driverAccessCargoFlow"
                name="access-cargo-flow"
                :disabled="form.driver.driverAccessAuto"
              >
                Допуск Грузопровод
              </b-form-checkbox>
            </b-col>
          </b-row>
        </div>
        <div class="custom-fieldset">
          <h5 class="custom-fieldset__title">
            Данные по доверенностям
          </h5>
          <p v-if="form.attorneys.length">
            Доверенности
          </p>
          <ul
            v-if="form.attorneys.length"
            class="route-attorneys"
          >
            <li
              v-for="(attorney, index) in form.attorneys"
              :key="index"
              class="route-attorney"
            >
              Доверенность <a
                :href="`/contractors/id${$store.state.user.ownerId}/attorney/id${attorney.id}`"
                target="_blank"
              >№{{ attorney.number }}</a>
              <span
                v-if="attorney.status === 0"
                class="text-secondary"
              > (На рассмотрении)</span>
              <span
                v-else-if="attorney.status === 1"
                class="text-success"
              > (Действует)</span>
              <span
                v-else-if="attorney.status === 2"
                class="text-danger"
              > (Аннулирована)</span><br>
              Действует с  {{ formatDatetime(attorney.dateStart) }} по {{ formatDatetime(attorney.dateEnd) }}<br>
              <span v-if="attorney.file.file === undefined" />
              <span v-else>
                Загруженный файл: <a
                  :href="'/files/' + attorney.file.file.guid"
                  target="_blank"
                  @click.prevent="downloadFile(attorney.file.file)"
                >{{ attorney.file.file.originalName }}</a>
              </span>
            </li>
          </ul>
          <b-button
            variant="primary"
            size="sm"
            class="mb-3"
            :class="{'loading' : loading}"
            :disabled="loading"
            @click="confirmAttorneysAddModal.show = true"
          >
            Добавить доверенность
          </b-button>
        </div>
      </div>
      <div class="fixed-buttons">
        <b-button
          :disabled="loading"
          type="button"
          variant="secondary"
          class="mr-2"
          :class="{'loading' : loading}"
          @click="$router.go(-1)"
        >
          Назад
        </b-button>
        <b-button
          v-if="$store.state.user.ownerType === 'contractor' && $store.state.user.ownerId === Number(contractorId) && form.driver.status === 0 && $store.getters.isAdminOrManager"
          type="button"
          variant="success"
          class="mr-2"
          :class="{'loading' : loading}"
          :disabled="loading"
          @click="contractorsDriverToCheck()"
        >
          Отправить на проверку
        </b-button>
        <b-button
          v-if="!form.driver.isCancel && $store.state.user.ownerType === 'contractor' &&
            $store.state.user.ownerId === Number(contractorId) &&
            (form.driver.status === 0 || form.driver.status === 1 || form.driver.status === 3) &&
            $store.getters.isAdminOrManager"
          :href="'/drivers/driver/id'+driverId"
          variant="primary"
          class="mr-2"
          :class="{'loading' : loading}"
          :disabled="loading"
        >
          Редактировать
        </b-button>
        <driver-inn-edit
          v-if="!form.driver.isCancel &&
            $store.state.user.ownerType === 'contractor' &&
            $store.state.user.ownerId === Number(contractorId) &&
            (form.driver.status === 0 || form.driver.status === 1) &&
            $store.getters.isAdminOrManager"
          v-model="form.driver.inn"
          :driver-id="driverId"
        />
        <b-button
          v-if="!form.driver.isCancel &&
            $store.state.user.ownerType === 'contractor' &&
            $store.state.user.ownerId === Number(contractorId) &&
            (
              form.driver.status === 0 ||
              form.driver.status === 1 ||
              form.driver.status === 3 ||
              form.driver.status === 5
            ) &&
            $store.getters.isAdmin"
          type="button"
          variant="danger"
          :class="{'loading' : loading}"
          :disabled="loading"
          @click="confirmContractorsDriverDelete()"
        >
          Удалить
        </b-button>
      </div>
    </div>

    <b-modal
      v-model="confirmAttorneysAddModal.show"
      title="Добавление доверенности"
      class="modal-warning"
      cancel-title="Назад"
      :ok-disabled="!Boolean(confirmAttorneysAddModal.number) || !Boolean(confirmAttorneysAddModal.date) || !Boolean(confirmAttorneysAddModal.dateStart) || !Boolean(confirmAttorneysAddModal.dateEnd) || !Boolean(confirmAttorneysAddModal.file)"
      ok-title="Отправить"
      @ok="attorneysAdd()"
    >
      <p>Заполните поля и нажмите кнопку "Отправить", доверенность автоматически прикрепится к данному водителю в статусе "На рассмотрении"</p>
      <b-form-group
        id="attorneyDriverInputGroup"
        label="Водитель"
        label-for="attorney-driver"
      >
        <b-form-input
          id="attorney-driver"
          v-model.trim="confirmAttorneysAddModal.driver"
          type="text"
          name="attorney-driver"
          autocomplete="attorney-driver"
          disabled
        />
      </b-form-group>
      <b-form-group
        id="attorneyNumberInputGroup"
        label="Номер доверенности"
        label-for="attorney-number"
      >
        <b-form-input
          id="attorney-number"
          v-model.trim="confirmAttorneysAddModal.number"
          type="text"
          name="attorney-number"
          autocomplete="attorney-number"
          required
        />
      </b-form-group>
      <b-form-group
        id="attorneyDateInputGroup"
        label="Дата доверенности"
        label-for="attorney-date"
      >
        <el-date-picker-input
          id="attorney-date"
          v-model.trim="confirmAttorneysAddModal.date"
          type="date"
          name="attorney-date"
          autocomplete="attorney-date"
          format="dd.MM.yyyy"
          :picker-options="pickerOptions"
          required
        />
      </b-form-group>
      <b-form-group
        id="attorneyDateStartInputGroup"
        label="Период действия с"
        label-for="attorney-date-start"
      >
        <el-date-picker-input
          id="attorney-date-start"
          v-model.trim="confirmAttorneysAddModal.dateStart"
          type="date"
          name="attorney-date-start"
          autocomplete="attorney-date-start"
          format="dd.MM.yyyy"
          :picker-options="pickerOptions"
          required
        />
      </b-form-group>
      <b-form-group
        id="attorneyDateEndInputGroup"
        label="Период действия по"
        label-for="attorney-date-end"
      >
        <el-date-picker-input
          id="attorney-date-end"
          v-model.trim="confirmAttorneysAddModal.dateEnd"
          type="date"
          name="attorney-date-end"
          autocomplete="attorney-date-end"
          format="dd.MM.yyyy"
          :picker-options="pickerOptions"
          required
        />
      </b-form-group>
      <b-form-group
        id="attorneyInputGroup"
        label="Доверенность"
        label-for="attorney"
      >
        <b-form-file
          id="attorney"
          v-model="confirmAttorneysAddModal.file"
          v-b-popover.hover="'Максимальный объем загружаемого файла, не должен превышать 15MB'"
          name="attorney"
          accept=".txt, .rtf, .doc, .docx, .pdf, .rar, .zip, .xls, .xlsx, .jpg, .jpeg, .png, .svg"
          placeholder="Файл не выбран"
          browse-text="Выбрать"
          drop-placeholder="Перетащите файл сюда"
          @input="uploadAttorneyFile('file')"
        />
        <div
          v-if="confirmAttorneysAddModal.file.file"
          class="mt-2"
        >
          Загруженный файл:
          <a
            :href="'/files/' + confirmAttorneysAddModal.file.file.guid"
            target="_blank"
            @click.prevent="downloadFile(confirmAttorneysAddModal.file.file)"
          >{{ confirmAttorneysAddModal.file.file.originalName }}</a>
        </div>
      </b-form-group>
    </b-modal>
  </div>
</template>

<script>
import ElDatePickerInput from '../../components/ElDatePickerInput.vue';
import {validationMixin} from 'vuelidate';
import {required, minLength, email, maxLength} from 'vuelidate/lib/validators';
import notifications from '../../components/mixins/notifications';
import {
  contractorsDriverRead,
  contractorsDriverToCheck,
  contractorsDriverDelete,
  passportTypesList,
  downloadFile,
  viewFile, contractorsAttorneyCreate, documentCreate, driverCheckLocations,
} from '../../services/api';
import moment from 'moment';
import multiplePhonesInput from '../Forms/MultiplePhonesInput';
import ExampleDocument from '@/views/ExampleDocuments/ExampleDocument';
import DriverInnEdit from '@/components/DriverInnEdit';


export default {
  name: 'DriverView',
  components: {DriverInnEdit, ExampleDocument, multiplePhonesInput, ElDatePickerInput},
  mixins: [validationMixin, notifications],
  props: ['contractorId', 'driverId'],
  data() {
    return {
      driverCheckLocations: [],
      accessType: null,
      passportTypes: [],
      form: {
        driver: {
          id: null,
          passportType: null,
          passportSeries: '',
          passportNumber: '',
          passportIssueDate: '',
          passportIssuedBy: '',
          surname: '',
          name: '',
          patronymic: '',
          birthDate: '',
          driverLicense: '',
          driverAccessAuto: false,
          driverAccessContainer: false,
          driverAccessCargoFlow: false,
          inn: null,
        },
        checkLocation: null,
        instruction: null,
        infoSheet: null,
        criminalZonesSheet: null,
        powerOfAttorney: null,
        selfie: null,
        checkType: null,
        passportScreenFirst: null,
        passportScreenSecond: null,
        contractFiles: [],
        driverLicenseScreens: [],
        driverPhones: [],
        attorneys: [],
        customerComment: null,
      },
      confirmAttorneysAddModal: {
        show: false,
        driver: '',
        number: '',
        date: '',
        dateStart: '',
        dateEnd: '',
        file: '',
      },
      pickerOptions: {
        firstDayOfWeek: 1,
      },
      loading: false,
    };
  },
  computed: {
    formStr() {
      const calculationFormData = Object.assign({}, this.form);
      calculationFormData.driver.passportIssueDate = moment(
        calculationFormData.driver.passportIssueDate,
      ).format();
      calculationFormData.driver.birthDate = moment(
        calculationFormData.driver.birthDate,
      ).format();
      calculationFormData.attorneys.map((attorney) => {
        if (attorney.file) {
          delete attorney.file.file;
        } else {
          attorney.file = {id: attorney.id};
          delete attorney.id;
        }
        Object.keys(attorney).map((item) => {
          if (/date/.test(item)) {
            attorney[item] = moment(attorney[item]).format();
          }
        });
      });
      return calculationFormData;
    },
    attorneyFormStr() {
      const calculationFormData = {};
      calculationFormData.drivers = [{id: this.form.driver.id}];
      if (this.confirmAttorneysAddModal.number) {
        calculationFormData.number = this.confirmAttorneysAddModal.number;
      }
      if (this.confirmAttorneysAddModal.date) {
        calculationFormData.date = moment(this.confirmAttorneysAddModal.date).format('YYYY-MM-DD');
      }
      if (this.confirmAttorneysAddModal.dateStart) {
        calculationFormData.dateStart = moment(this.confirmAttorneysAddModal.dateStart).format('YYYY-MM-DD');
      }
      if (this.confirmAttorneysAddModal.dateEnd) {
        calculationFormData.dateEnd = moment(this.confirmAttorneysAddModal.dateEnd).format('YYYY-MM-DD');
      }
      if (this.confirmAttorneysAddModal.file) {
        calculationFormData.file = this.confirmAttorneysAddModal.file;
      }
      return calculationFormData;
    },
  },
  validations: {
    form: {
      driver: {
        passportType: {
          required,
        },
        passportSeries: {
          required,
        },
        passportNumber: {
          required,
        },
        passportIssueDate: {
          required,
        },
        passportIssuedBy: {
          required,
        },
        surname: {
          required,
        },
        name: {
          required,
        },
        patronymic: '',
        birthDate: {
          required,
        },
        driverLicense: {
          required,
        },
        driverAccessAuto: '',
        driverAccessContainer: '',
        driverAccessCargoFlow: '',
        inn: {
          required,
          minLength: minLength(12),
          maxLength: maxLength(12),
        },
      },
      passportScreenFirst: {
        required,
      },
      passportScreenSecond: {
        required,
      },
      contractFiles: {
        required,
      },
      driverLicenseScreens: {
        required,
      },
    },
  },
  watch: {
    '$route'() {
      this.contractorsDriverRead(this.contractorId, this.driverId);
    },
  },
  mounted() {
    this.passportTypesList();
    this.contractorsDriverRead(this.contractorId, this.driverId);
    this.getLocations();
  },
  methods: {
    viewFile,
    downloadFile,
    formatDatetime(date) {
      return date ? moment(date).format('DD.MM.YYYY') : '';
    },
    async attorneysAdd() {
      this.loading = true;
      const response = await contractorsAttorneyCreate(this.$store.state.user.ownerId, this.attorneyFormStr);
      if (response && response.status === 201) {
        this.showSuccess('Доверенность создана');
        this.contractorsDriverRead(this.$store.state.user.ownerId, this.driverId);
        this.confirmAttorneysAddModal.number = '';
        this.confirmAttorneysAddModal.date = '';
        this.confirmAttorneysAddModal.dateStart = '';
        this.confirmAttorneysAddModal.dateEnd = '';
        this.confirmAttorneysAddModal.file = '';
      }
      this.loading = false;
    },
    async passportTypesList(params) {
      this.loading = true;
      const response = await passportTypesList(params);
      if (response && response.status === 200) {
        this.passportTypes = response.data.items.map((type) => {
          return {
            value: type.id,
            text: type.type,
          };
        });
      }
      this.loading = false;
    },
    async contractorsDriverRead(contractorId, driverId) {
      this.loading = true;
      const response = await contractorsDriverRead(contractorId, driverId);
      if (response && response.status === 200) {
        let fio = response.data.driver.surname + ' ' + response.data.driver.name;
        if (response.data.driver.patronymic) {
          fio = fio + ' ' + response.data.driver.patronymic;
        }
        this.confirmAttorneysAddModal.driver = fio;
        Object.keys(response.data.driver).map((item) => {
          if (item === 'passportType') {
            this.form.driver[item] = response.data.driver[item].id;
          } else if (
            item === 'guid' ||
            item === 'driverBlockedSecurity' ||
            item === 'createdOn' ||
            item === 'updatedOn'
          ) {
            // nothing
          } else {
            this.form.driver[item] = response.data.driver[item];
          }
        });
        if (response.data.contractFiles) {
          this.form.contractFiles = response.data.contractFiles;
        }
        if (response.data.driverLicenseScreens) {
          this.form.driverLicenseScreens = response.data.driverLicenseScreens;
        }
        if (response.data.passportScreenFirst) {
          this.form.passportScreenFirst = response.data.passportScreenFirst;
        }
        if (response.data.passportScreenSecond) {
          this.form.passportScreenSecond = response.data.passportScreenSecond;
        }
        if (response.data.instruction) {
          this.form.instruction = response.data.instruction;
        }
        if (response.data.infoSheet) {
          this.form.infoSheet = response.data.infoSheet;
        }
        if (response.data.criminalZonesSheet) {
          this.form.criminalZonesSheet = response.data.criminalZonesSheet;
        }
        if (response.data.powerOfAttorney) {
          this.form.powerOfAttorney = response.data.powerOfAttorney;
        }
        if (response.data.selfie) {
          this.form.selfie = response.data.selfie;
        }
        if (response.data.checkLocation) {
          this.form.checkLocation = response.data.checkLocation.id;
        }
        if (response.data.checkType !== undefined) {
          this.form.checkType = response.data.checkType;
        } else {
          this.form.checkType = null;
        }
        if (response.data.driver.driverPhones) {
          this.form.driverPhones = response.data.driver.driverPhones;
        }
        if (response.data.driver.birthDate) {
          this.form.driver.birthDate = moment(response.data.driver.birthDate).format('YYYY-MM-DD');
        }
        if (this.form.driver.driverAccessAuto) {
          this.accessType = 'auto';
        } else if (this.form.driver.driverAccessContainer) {
          this.accessType = 'container';
        } else if (this.form.driver.driverAccessCargoFlow) {
          this.accessType = 'cargoFlow';
        }
        this.form.driverPhones = response.data.driverPhones;
        if (response.data.attorneys) {
          response.data.attorneys.map((attorney) => {
            if (attorney.status === 2) {
              const updateTime = moment(attorney.updatedOn).format('x');
              const currentTime = moment().format('x');
              const limit = 604800000;
              if (currentTime - updateTime >= limit) {
                attorney.hidden = true;
              }
            }
            if (!attorney.file) {
              attorney.file = {};
            }
            this.form.attorneys.push(attorney);
          });
        }
        if (response.data.customerComment !== null) {
          this.form.customerComment = response.data.customerComment;
        }
      }
      this.loading = false;
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    async attorneyScreenCreate(key, upload) {
      this.loading = true;
      const response = await documentCreate(upload);
      if (response && response.status === 201) {
        this.confirmAttorneysAddModal[key] = response.data;
        this.showSuccess('Документ загружен');
      }
      this.loading = false;
    },
    uploadAttorneyFile(key) {
      const file = this.confirmAttorneysAddModal[key];
      if (file.size >= 15 * 1024 * 1024) {
        this.showError('Файл слишком большой. Максимальный объем загружаемого файла не должен превышать 15MB.');
      } else {
        this.getBase64(file).then((data) => {
          const upload = {
            base64file: data,
            originalName: file.name,
          };
          this.attorneyScreenCreate(key, upload);
        });
      }
    },
    async contractorsDriverToCheck() {
      this.loading = true;
      const response = await contractorsDriverToCheck(this.contractorId, this.driverId);
      if (response && response.status === 200) {
        this.showSuccess('Водитель отправлен на первичную проверку');
        this.$router.go();
      }
      this.loading = false;
    },
    confirmContractorsDriverDelete() {
      this.$bvModal
        .msgBoxConfirm('Вы уверены что хотите удалить водителя?', {
          okTitle: 'Да',
          cancelTitle: 'Назад',
        })
        .then((confirm) => {
          if (confirm) {
            this.contractorsDriverDelete();
          }
        });
    },
    async contractorsDriverDelete() {
      this.loading = true;
      const response = await contractorsDriverDelete(this.contractorId, this.driverId);
      if (response && response.status === 200) {
        this.showSuccess('Водитель удален');
        this.$router.push({path: '/drivers'});
      }
      this.loading = false;
    },
    async getLocations() {
      const response = await driverCheckLocations({});
      if (response && response.status === 200) {
        this.driverCheckLocations = response.data;
      }
    },
  },
};
</script>

<style lang="scss">
</style>

