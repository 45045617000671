<template>
  <div>
    <div>
      <b-form-group
        v-for="(phone, index) in $attrs.value"
        :key="`phone-${index}`"
      >
        <b-row>
          <b-col cols="11">
            <phone-input v-model="$attrs.value[index]" :driver-status="driverStatus"/>
          </b-col>
          <b-col cols="1">
            <b-btn
              v-if="!$attrs.value[index].deletionMark"
              variant="light"
              :disabled="$attrs.value.length === 1 || driverStatus == 3"
              @click="deletePhone(index)"
            >
              &times;
            </b-btn>
            <b-button
              v-if="$attrs.value[index].deletionMark"
              variant="danger"
              @click="unDeletePhone(index)"
            >
              &times;
            </b-button>
          </b-col>
        </b-row>
      </b-form-group>
    </div>
    <b-btn @click="addPhone" :disabled="driverStatus == 3">
      Добавить Телефон
    </b-btn>
  </div>
</template>
<script>
import PhoneInput from './PhoneInput';

export default {
  name: 'MultiplePhonesInput',
  components: {PhoneInput},
  props: ['driverStatus'],
  data: function() {
    return {
      phone: {
        countryCode: '',
        areaCode: '',
        localNumber: '',
        additionalNumber: '',
      },
      phones: [],
    };
  },
  methods: {
    updateForm() {
      this.$emit('input', this.$attrs.value);
    },
    addPhone() {
      this.$attrs.value.push({...this.phone});
      this.updateForm();
    },
    deletePhone(index) {
      if (this.$attrs.value[index].id) {
        this.$set(this.$attrs.value[index], 'deletionMark', true);
      } else {
        this.$attrs.value.splice(index, 1);
      }
      this.$forceUpdate();
    },
    unDeletePhone(index) {
      this.$set(this.$attrs.value[index], 'deletionMark', false);
      this.$forceUpdate();
    },
  },
};
</script>

