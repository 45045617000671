<template>
  <b-row>
    <b-col md="3">
      <b-form-select
        v-model="$attrs.value.countryCode"
        :options="countryCodes"
        :disabled="driverStatus == 3"
        required
        @input="updateForm()"
      />
    </b-col>
    <b-col md="3">
      <b-form-input
        v-model="$attrs.value.areaCode"
        :disabled="driverStatus == 3"
        type="text"
        placeholder="Код города (код оператора)"
        required
        @keyup="updateAreaCode()"
        @keydown="updateAreaCode()"
        @blur="updateAreaCode()"
        @paste="updateAreaCode()"
        @input="updateForm()"
      />
    </b-col>
    <b-col md="3">
      <b-form-input
        v-model="$attrs.value.localNumber"
        :disabled="driverStatus == 3"
        type="text"
        placeholder="Короткий номер"
        required
        @keyup="updateLocalNumber()"
        @keydown="updateLocalNumber()"
        @blur="updateLocalNumber()"
        @paste="updateLocalNumber()"
        @input="updateForm()"
      />
    </b-col>
    <b-col md="3">
      <b-form-input
        v-model="$attrs.value.additionalNumber"
        type="text"
        placeholder="Доп. Номер"
        disabled
        @input="updateForm()"
      />
    </b-col>
  </b-row>
</template>
<script>
import notifications from '../../components/mixins/notifications';
import {phoneCountryCodesList} from '../../services/api';

export default {
  name: 'PhoneInput',
  mixins: [notifications],
  props: ['phone', 'driverStatus'],
  data: function() {
    return {
      form: {
        countryCode: '',
        areaCode: '',
        localNumber: '',
        additionalNumber: '',
      },
      countryCodes: [],
    };
  },
  mounted() {
    this.form = this.$attrs.value;
    this.phoneCountryCodesList();
  },
  methods: {
    async phoneCountryCodesList() {
      this.loading = true;
      const response = await phoneCountryCodesList({limit: 100});
      if (response && response.status === 200) {
        this.countryCodes = response.data.items.map((item) => {
          return {text: '+' + item.code + ' ' + item.country, value: item};
        });
        if (!this.$attrs.value.countryCode) {
          this.form.countryCode = this.countryCodes[0].value; // Default value
        } else {
          this.form.countryCode = this.$attrs.value.countryCode;
        }
      }
      this.loading = false;
    },
    updateAreaCode() {
      this.$attrs.value.areaCode = this.$attrs.value.areaCode.replace(/\D+/g,'');
    },
    updateLocalNumber() {
      this.$attrs.value.localNumber = this.$attrs.value.localNumber.replace(/\D+/g,'');
    },
    updateForm() {
      this.$emit('input', this.form);
    },
  },
};
</script>

